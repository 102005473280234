<template>
  <div style="position: absolute; top: 45%; left: 45%; text-align: center">
    <div><h3>Unauthorized</h3></div>
    <div style="padding-top: 20px">
      <router-link to="/">Go to Home</router-link>
    </div>
  </div>
</template>
<script>
export default {};
</script>
